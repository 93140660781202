import { ThunkDispatch } from 'redux-thunk';
import * as actionTypes from 'store/action-types';
import AuthService from 'shared/services/auth.service';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/constants';
import { createAction } from 'shared/util/utility';
import { Action } from 'shared/interface';
import { Params } from '../interface/register';

const registerUser = (data: Params) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.REGISTER_USER_INIT));
		HttpService.post(API_CONFIG.path.register, data).then(() => {
			const data = AuthService.getTokenData();
			const isTokenDataAvailable = data.token && data.permission === 1 && data.tokenType === 'authorize';
			if (isTokenDataAvailable) {
				AuthService.setRegisterFlag(1);
			}
			dispatch(createAction(actionTypes.REGISTER_USER_SUCCESS));
			dispatch(createAction('RESET_MODAL'));
		}).catch((err: Error) => {
			dispatch(createAction(actionTypes.REGISTER_USER_FAIL));
		});
	};
}

export {
	registerUser,
}
