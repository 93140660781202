import React from 'react';
import { useTranslation } from 'react-i18next';

const Translate: React.FC<{ text: string }> = (props) => {
	const { t } = useTranslation();
	return (
		<span>
			{t(props.text)}
		</span>
	);
}

export {
	Translate
}
