import React, { Component } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { FormikValues } from 'formik';

import { API_CONFIG, PRIVACY_POLICY_URL } from 'shared/constants/constants';
import HttpService from 'shared/services/http.service';
import localizationConstants from 'shared/util/translation/constants';
import { CustomForm } from 'shared/components/form/form';
import LoginWrapper from 'shared/hoc/auth/loginWrapper';
import { FieldConfig } from 'shared/components/form/inputTypes';
import * as schema from 'shared/constants/validation-schema';
import { Translate } from 'shared/components/translate';

interface OwnProps extends RouteComponentProps<{ token: string }> { }

interface UIState {
	isLoading: boolean;
}

/**
 * ShopifyConnect - Connect with shopify service
 */
class ShopifyConnect extends Component<OwnProps> {
	state: UIState = {
		isLoading: false
	};

	render() {
		const { isLoading } = this.state;
		return (
			<>
				<LoginWrapper className='forget-password-wrapper'>
					<CustomForm
						title={localizationConstants.addNewShop}
						showLabels={true}
						formClassName='m-t'
						schema={schema.addStoreValidationSchema}
						onSubmit={this.connectWithShopify}
						loading={isLoading}
						fieldConfig={fieldConfig}
						submitBtnText={localizationConstants.connectWithShopify}
						submitBtnClassName='block full-width m-b'
					/>
					<div className='d-flex justify-content-space-around'>
						<Link to='/login'>
							<small><Translate text={localizationConstants.login} /></small>
						</Link>
						<a target='_blank' rel="noopener noreferrer" href={PRIVACY_POLICY_URL}>
							<small><Translate text={localizationConstants.privacyPolicy} /></small>
						</a>
					</div>
				</LoginWrapper>
			</>
		)
	}

	connectWithShopify = (values: FormikValues) => {
		this.setState({ isLoading: true });
		HttpService.post(API_CONFIG.path.addShop, values).then((response: any) => {
			this.setState({ isLoading: false });
			if (!!response.redirect_url) {
				window.location = response.redirect_url;
			}
		}).catch(() => {
			this.setState({ isLoading: false });
		});
	};
}

const fieldConfig: FieldConfig[] = [
	{ type: 'text', label: localizationConstants.domain, name: 'domain', placeHolder: 'example.myshopify.com' },
]

export default ShopifyConnect;
