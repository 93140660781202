import React from 'react';
import { Redirect, Link, RouteComponentProps } from 'react-router-dom';
import { FormikValues } from 'formik';

import AuthService from 'shared/services/auth.service';
import { CustomForm } from 'shared/components/form/form';
import { FieldConfig } from 'shared/components/form/inputTypes';

import { ResetPasswordDispatchProps, ResetPasswordParams, MapStateProps } from '../interface/password.interface';
import LoginWrapper from 'shared/hoc/auth/loginWrapper';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/components/translate';
import * as schema from 'shared/constants/validation-schema';

interface OwnProps extends ResetPasswordDispatchProps, MapStateProps, RouteComponentProps<{ token: string }> { }

const ResetPasswordForm: React.FC<OwnProps> = (props) => {
	const isLoggedIn = AuthService.checkLogin();
	if (isLoggedIn || props.closeModal) {
		return (<Redirect to='/login' />)
	}
	// define reset password submit function
	const onResetPassword = (values: FormikValues) => props.resetPassword(values as ResetPasswordParams, props.match.params.token);

	return (
		<LoginWrapper>
			<CustomForm
				title={localizationConstants.resetYourPassword}
				showLabels={true}
				formClassName='m-t'
				schema={schema.resetPasswordValidationSchema}
				onSubmit={onResetPassword}
				loading={props.loading}
				fieldConfig={fieldConfig}
				submitBtnText={localizationConstants.resetPassword}
				submitBtnClassName='block full-width m-b'
			/>
			<div className='text-center'>
				<Link to='/login'>
					<small><Translate text={localizationConstants.backToLogin} /></small>
				</Link>
			</div>
		</LoginWrapper>
	)
}

// field config of reset password form
const fieldConfig: FieldConfig[] = [
	// { type: 'email', label: 'Email', name: 'email' },
	{ type: 'password', label: localizationConstants.newPassword, name: 'password' },
	{ type: 'password', label: localizationConstants.confirmPassword, name: 'password_confirmation' },
];

export default ResetPasswordForm;
