import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Spinner from 'shared/components/spinner/spinner';
import { UserProfileResponse } from 'features/login/interface/login.interface';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/components/translate';

interface Props {
	userData: UserProfileResponse;
	loading: boolean;
	handleUpdateProfile: () => void;
}


const Profile: React.FC<Props> = (props) => {
	//  if userdata is empty, show none
	if (isEmpty(props.userData)) {
		return null;
	}

	return (
		<div className='row'>
			<div className='cols-xs-12 col-sm-12 col-md-12 col-lg-12'>
				<div className='custom-card'>
					<div className='card-heading d-flex'>
						<h4><Translate text={localizationConstants.profile} /></h4>
						<div data-testid='edit-profile' className='edit-profile-btn' onClick={props.handleUpdateProfile}>
							<i className='fa fa-pencil' />
						</div>
					</div>
					{props.loading ? <Spinner /> :
						<div className='card-description-wrapper'>
							<div className='desc-item d-flex align-items-center'>
								<label><Translate text={localizationConstants.name} />:</label>
								<p>{props.userData.user.name}</p>
							</div>
							<div className='desc-item d-flex align-items-center'>
								<label><Translate text={localizationConstants.email} />:</label>
								<p>{props.userData.user.email}</p>
							</div>
							<div className='desc-item d-flex align-items-center'>
								<label><Translate text={localizationConstants.debAuto} />:</label>
								<p>{props.userData.user.deb_auto_id || '-'}</p>
							</div>
							<div className='desc-item d-flex align-items-center'>
								<label><Translate text={localizationConstants.language} />:</label>
								{props.userData.user.language === 'de' &&
									<p><Translate text={localizationConstants.german} /></p>
								}
								{props.userData.user.language === 'en' &&
									<p><Translate text={localizationConstants.english} /></p>
								}
							</div>
						</div>}
				</div>
			</div>
		</div>
	)
}

export default Profile;
