import React, { Component } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import LoginWrapper from 'shared/hoc/auth/loginWrapper';
import { API_CONFIG } from 'shared/constants/constants';
import AuthService from 'shared/services/auth.service';
import HttpService from 'shared/services/http.service';
import { Loader } from 'shared/components/spinner/spinner';
import { refreshLanguage } from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/components/translate';
import * as actionTypes from 'store/action-types';
import { LoginResponse, UserProfileResponse } from 'features/login/interface/login.interface';
import { createAction } from 'shared/util/utility';
import { getSideNavMenu } from 'features/login/store/login.action';
import store from 'store';

interface OwnProps extends RouteComponentProps { }

/**
 * shopAccessValidate - Validate access store info from shopify
 */
class shopAccessValidate extends Component<OwnProps> {
	state = {
		isLoading: false,
		isVerified: false,
		shopId: null,
		message: ''
	};

	componentDidMount = () => {
		this.validateShopAccess();
	};

	render() {
		const { isLoading, isVerified, shopId, message } = this.state;
		if (isVerified) {
			return (<Redirect to={`/shop/${shopId}`} />)
		}
		return (
			<LoginWrapper className='validate-card-wrapper'>
				<div className='card'>
					{isLoading && <Loader />}
					{!isLoading && !isVerified && message && <Reject iconClassName='danger' />}
					<div className='content-wrapper'>
						{isLoading && 'Authorizing...'}
						{!isLoading && !isVerified && <Translate text={message} />}
					</div>
				</div>
			</LoginWrapper>
		)
	}

	/**
	 * validateShopAccess - validate shop access token
	 */
	validateShopAccess = () => {
		const parsed = queryString.parse(this.props.location.search);
		const params = {
			token: parsed.token,
		};
		this.setState({ isLoading: true });
		
		HttpService.post(API_CONFIG.path.validateShopAccess, params).then((response: { loginData: LoginResponse, shopData: any }) => {
			AuthService.removeAuthData();
			AuthService.setAuthData(response.loginData);
			HttpService.get(API_CONFIG.path.profile).then((profileResponse: UserProfileResponse) => {
				profileResponse.sidebarMenu = getSideNavMenu(profileResponse);
				AuthService.setUserData(profileResponse);
				refreshLanguage(profileResponse.user.language || 'de');
				store.dispatch(createAction(actionTypes.AUTH_SUCCESS, profileResponse));				
				this.setState({ isLoading: false, isVerified: true, shopId: response.shopData.id });
			});
		}).catch(() => {
			this.setState({ isLoading: false, isVerified: false, message: localizationConstants.invalidToken });
		});
	}
}

const Reject: React.FC<{ className?: string, iconClassName?: string }> = (props) => {
	return (
		<div className={[props.className || '', 'spinner-wrapper'].join(' ')}>
			<div className={[props.iconClassName || '', 'loading-icon fa fa-exclamation-triangle'].join(' ')} />
		</div>
	);
}

export default shopAccessValidate;
