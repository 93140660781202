import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';

import LoginWrapper from 'shared/hoc/auth/loginWrapper';
import { API_CONFIG } from 'shared/constants/constants';
import HttpService from 'shared/services/http.service';
import { Loader } from 'shared/components/spinner/spinner';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/components/translate';

interface OwnProps extends RouteComponentProps<{ id: string }> { }

/**
 * Validate - email verification
 */
class Validate extends Component<OwnProps> {
	state = {
		isLoading: false,
		isVerified: false,
		message: ''
	};

	componentDidMount = () => {
		this.validateEmail();
	};

	render() {
		const { isLoading, isVerified, message } = this.state;
		return (
			<LoginWrapper className='validate-card-wrapper'>
				<div className='card'>
					{isLoading && <Loader />}
					{!isLoading && isVerified && <Verified iconClassName='success' />}
					{!isLoading && !isVerified && <Reject iconClassName='danger' />}
					<div className='content-wrapper'>
						{isLoading && 'validating...'}
						{!isLoading && isVerified &&
							<>
								<div className='val-text'>
									<Translate text={message} />
								</div>
								<a className='btn btn-primary text-capitalize mt-3' href='/login'>
									<Translate text={localizationConstants.login} />
								</a>
							</>
						}
						{!isLoading && !isVerified && <Translate text={message} />}
					</div>
				</div>
			</LoginWrapper>
		)
	}

	/**
	 * validateEmail - check user email verification
	 */
	validateEmail = () => {
		const parsed = queryString.parse(this.props.location.search);
		const params = {
			signature: parsed.signature,
			email: parsed.email,
		};
		this.setState({ isLoading: true });
		HttpService.get(API_CONFIG.path.emailVerification, params).then((response: any) => {
			this.setState({ isLoading: false, isVerified: true, message: response.already_verified ? localizationConstants.emailAlreadyValidatedMessage : localizationConstants.emailVerificationSuccessMessage });
		}).catch((err: Error) => {
			this.setState({ isLoading: false, isVerified: false, message: localizationConstants.emailVerificationFailMessage });
		});
	}
}

const Verified: React.FC<{ className?: string, iconClassName?: string }> = (props) => {
	return (
		<div className={[props.className || '', 'spinner-wrapper'].join(' ')}>
			<div className={[props.iconClassName || '', 'loading-icon fa fa-check-circle-o'].join(' ')} />
		</div>
	);
}

const Reject: React.FC<{ className?: string, iconClassName?: string }> = (props) => {
	return (
		<div className={[props.className || '', 'spinner-wrapper'].join(' ')}>
			<div className={[props.iconClassName || '', 'loading-icon fa fa-exclamation-triangle'].join(' ')} />
		</div>
	);
}

export default Validate;
