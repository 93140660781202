import { lazy } from 'react';
import { withRouter } from 'react-router';

const Dashboard = withRouter(lazy(() => import('features/dashboard/component/dashboard')));

const Shop = withRouter(lazy(() => import('features/shop/container/shop')));
const ShopDetail = withRouter(lazy(() => import('features/shop/container/inventory')));
const ItemDetail = withRouter(lazy(() => import('features/shop/container/itemDetail')));

export {
	Dashboard,
	Shop, ShopDetail, ItemDetail
}
