import React, { ChangeEventHandler } from 'react';
import { Field, ErrorMessage } from 'formik';
import FieldErrorMessage from './error';
import { Translate } from '../translate';
import Option from './option';
import { useTranslation } from 'react-i18next';
export interface DropDownAndCheckboxOptions {
	name: string;
	value: string | number
}
export interface FieldConfig {
	type: 'text' | 'textarea' | 'email' | 'password' | 'number' | 'dropdown' | 'checkbox' | 'dropdown';
	label: string;
	name: string;
	className?: string;
	placeHolder?: string;
	otherOptions?: {
		dropDownOptions?: DropDownAndCheckboxOptions[];
		checkboxOptions?: DropDownAndCheckboxOptions[];
		isMultiSelect?: boolean;
	}
}
interface TextFieldProps {
	name: string;
	placeholder: string;
	config: FieldConfig;
	setFieldValue: (field: string, value: any) => void;

	readOnly?: boolean;
	type?: 'text' | 'textarea' | 'email' | 'password' | 'number' | 'checkbox' | 'dropdown';
	className?: string;
	autoComplete?: string;
	showLabels?: boolean;
}

const RenderInput: React.FC<TextFieldProps & { field: any }> = (props) => {
	const { t } = useTranslation();
	switch (props.type) {
		// render text input in case of text, textarea, email, password and number
		case 'textarea':
			return (
				<>
					{props.showLabels && props.config.label &&
						<label className='text-capitalize input-label-wrapper'>
							<Translate text={props.config.label} />
						</label>}
					<textarea
						{...props.field}
						value={getValue(props.field.value)}
						id={props.name}
						className={`${props.className || ''} form-control`}
						placeholder={t(props.placeholder)}
						readOnly={props.readOnly}
						autoComplete={`${props.autoComplete || 'off'}`}
					/>
					<ErrorMessage name={props.config.name} component={FieldErrorMessage} />
				</>
			)
		case 'text':
		case 'email':
		case 'password':
		case 'number':
			return (
				<>
					{props.showLabels && props.config.label &&
						<label className='text-capitalize input-label-wrapper'>
							<Translate text={props.config.label} />
						</label>}
					<input
						{...props.field}
						value={getValue(props.field.value)}
						id={props.name}
						type={props.type}
						className={`${props.className || ''} form-control`}
						placeholder={t(props.placeholder)}
						readOnly={props.readOnly}
						autoComplete={`${props.autoComplete || 'off'}`}
					/>
					<ErrorMessage name={props.config.name} component={FieldErrorMessage} />
				</>
			)
		case 'checkbox':
			return (
				<div className='form-group d-flex align-items-baseline'>
					{props.config.label &&
						<label className='text-capitalize col-xs-4 col-sm-2 control-label'>
							<Translate text={props.config.label} />
						</label>}
					<div className='checkbox-wrapper col-xs-8 col-sm-10'>
						{geCheckboxOptions(props.config).map((option) => {
							const setCheckboxvalue: ChangeEventHandler<HTMLInputElement> = (evt) => {
								props.setFieldValue(props.config.name, getCheckboxValue(props.field, evt));
							}
							const isChecked = (props.field.value || []).map((key: any) => (key || '').toString()).includes(option.value.toString());
							return (
								<div className='checkbox-content' key={option.value}>
									<label className='text-capitalize checkbox-label'>
										<Translate text={option.name} />
										<input
											placeholder={option.name}
											checked={isChecked}
											onChange={setCheckboxvalue}
											type='checkbox'
											name={option.name}
											value={option.value} />
										<span className='checkmark' />
									</label>
								</div>
							)
						})}
					</div>
					<ErrorMessage name={props.config.name} component={FieldErrorMessage} />
				</div>
			);

		// render dorpdown when dropdown type is provided
		case 'dropdown':
			const setDropdownValue: ChangeEventHandler<any> = (evt) => {
				if ((props.config.otherOptions || {}).isMultiSelect) {
					props.setFieldValue(props.config.name, [].slice.call(evt.target.selectedOptions).map((option: any) => option.value));
				} else {
					props.setFieldValue(props.config.name, evt.target.value);
				}
			}
			return (
				<div className='row'>
					{props.config.label &&
						<label className='text-capitalize col-sm-12 control-label'>
							<Translate text={props.config.label} />
						</label>}
					<div className='col-sm-12'>
						<select
							placeholder={props.config.placeHolder || props.config.label}
							value={props.field.value}
							onChange={setDropdownValue}
							name={props.config.name}
							className='form-control'
							multiple={(props.config.otherOptions || {}).isMultiSelect}
						>
							<option value=''>---SELECT---</option>
							{geDropDownOptions(props.config).map(option => (
								<Option key={option.name} name={option.name} value={option.value} />
							))}
						</select>
						<ErrorMessage name={props.config.name} component={FieldErrorMessage} />
					</div>
				</div>
			)
		default:
			return <></>
	}
}

/**
 * common input field component
 * renders input based on the field configuration
 * @param props { field, form: { touched, errors }, ...props }
 */
const Input: React.FC<TextFieldProps> = (props) => {
	const fieldRender = ({ field }: { field: any }) => {
		return <RenderInput {...props} field={field} />
	}

	return (
		<Field
			name={props.name}
			render={fieldRender}
		/>
	);
};

/**
 * getCheckboxValue - returns check box value, after changing value with change event of html input element
 * @param field - field returned by formik
 * @param evt - html input change event, linked with checkbox input
 */
const getCheckboxValue = (field: any, evt: React.ChangeEvent<HTMLInputElement>) => {
	// if field value is empty, or null initially, assign it as empty array of strings
	if (!field.value) {
		field.value = [];
	}
	const index = field.value.indexOf(evt.target.value.toString());
	// if event gives `checked` = true, push target value to field value
	if (evt.target.checked) {
		field.value.push(evt.target.value.toString());
	} else if (index !== -1) {
		// else remove target value from field value
		field.value.splice(index, 1);
	}
	// return value
	return field.value;
}

const geDropDownOptions = (config: FieldConfig) => {
	return ((config.otherOptions || {}).dropDownOptions || []);
}

const geCheckboxOptions = (config: FieldConfig) => {
	return ((config.otherOptions || {}).checkboxOptions || []);
}


const getValue = (value?: string | number) => {
	if (value === undefined || value === null) {
		return '';
	}
	return value;
};

export {
	Input,
};
