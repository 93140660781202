export const AUTH_INIT = 'AUTH_INIT';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const GET_PROFILE_INIT = 'GET_PROFILE_INIT';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

export const CHANGE_PASSWORD_INIT = 'CHANGE_PASSWORD_INIT';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const UPDATE_PROFILE_INIT = 'UPDATE_PROFILE_INIT';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';

export const FORGET_PASSWORD_INIT = 'FORGET_PASSWORD_INIT';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL';

export const RESET_PASSWORD_INIT = 'RESET_PASSWORD_INIT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const REGISTER_USER_INIT = 'REGISTER_USER_INIT';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';

export const RELATE_SHOP_INIT = 'RELATE_SHOP_INIT';
export const RELATE_SHOP_SUCCESS = 'RELATE_SHOP_SUCCESS';
export const RELATE_SHOP_FAIL = 'RELATE_SHOP_FAIL';

export const AUTH_LOGOUT_INIT = 'AUTH_LOGOUT_INIT';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAIL = 'AUTH_LOGOUT_FAIL';

export const FETCH_MACHINE_LIST_INIT = 'FETCH_MACHINE_LIST_INIT';
export const FETCH_MACHINE_LIST_SUCCESS = 'FETCH_MACHINE_LIST_SUCCESS';
export const FETCH_MACHINE_LIST_FAIL = 'FETCH_MACHINE_LIST_FAIL';

export const FETCH_COMPANIES_INIT = 'FETCH_COMPANIES_INIT';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAIL = 'FETCH_COMPANIES_FAIL';

export const FETCH_SHOPS_INIT = 'FETCH_SHOPS_INIT';
export const FETCH_SHOPS_SUCCESS = 'FETCH_SHOPS_SUCCESS';
export const FETCH_SHOPS_FAIL = 'FETCH_SHOPS_FAIL';

export const FETCH_SHOP_ITEMS_INIT = 'FETCH_SHOP_ITEMS_INIT';
export const FETCH_SHOP_ITEMS_SUCCESS = 'FETCH_SHOP_ITEMS_SUCCESS';
export const FETCH_SHOP_ITEMS_FAIL = 'FETCH_SHOP_ITEMS_FAIL';

export const FETCH_SHOP_ORDERS_INIT = 'FETCH_SHOP_ORDERS_INIT';
export const FETCH_SHOP_ORDERS_SUCCESS = 'FETCH_SHOP_ORDERS_SUCCESS';
export const FETCH_SHOP_ORDERS_FAIL = 'FETCH_SHOP_ORDERS_FAIL';

export const FETCH_TSE_BACKUP_HISTORY_INIT = 'FETCH_TSE_BACKUP_HISTORY_INIT';
export const FETCH_TSE_BACKUP_HISTORY_SUCCESS = 'FETCH_TSE_BACKUP_HISTORY_SUCCESS';
export const FETCH_TSE_BACKUP_HISTORY_FAIL = 'FETCH_TSE_BACKUP_HISTORY_FAIL';

export const NO_ACTION = 'NO_ACTION';
export const RESET_MODAL_STATUS = 'RESET_MODAL_STATUS';
export const RESET_MESSAGE = 'RESET_MESSAGE';
