import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';

const errorMessages = {
	required: (field: string) => {
		return i18n.t(localizationConstants.requiredFieldValidation, { field: i18n.t(field) });
	},
	select: (field: string) => {
		return i18n.t(localizationConstants.fieldSelectionValidation, { field: i18n.t(field) });
	},
	exactLength: (field: string, length: number) => {
		return i18n.t(localizationConstants.exactLengthValidation, { field: i18n.t(field), length });
	},
	minLength: (field: string, length: number) => {
		return i18n.t(localizationConstants.minLengthStrValidation, { field: i18n.t(field), length });
	},
	minLengthArray: (field: string, length: number) => {
		return i18n.t(localizationConstants.minLengthArrValidation, { field: i18n.t(field), length });
	},
	maxLength: (field: string, length: number) => {
		return i18n.t(localizationConstants.maxLengthStrValidation, { field: i18n.t(field), length });
	},
	maxLengthArray: (field: string, length: number) => {
		return i18n.t(localizationConstants.maxLengthArrValidation, { field: i18n.t(field), length });
	},
	minValue: (field: string, value: number) => {
		return i18n.t(localizationConstants.minValueValidation, { field: i18n.t(field), value });
	},
	maxValue: (field: string, value: number) => {
		return i18n.t(localizationConstants.maxValueValidation, { field: i18n.t(field), value });
	},
	validType: (field: string, type: string) => {
		return i18n.t(localizationConstants.typeValidation, { field: i18n.t(field), type: i18n.t(field) });
	},
	hardwareKeyValidate: () => {
		return i18n.t(localizationConstants.hardwareKeyValidation);
	},
	passwordMatchValidate: () => {
		return i18n.t(localizationConstants.passwordMatchValidation);
	},
	domainMatchValidate: () => {
		return i18n.t(localizationConstants.domainMatchValidation);
	}
}

const onConfirmMessages = {
	confirmRestore: (field: string) => {
		return i18n.t(localizationConstants.confirmRestoreMsg, { field: i18n.t(field) });
	},
	confirmDelete: (field: string) => {
		return i18n.t(localizationConstants.confirmDeleteMsg, { field: i18n.t(field) });
	},
	confirmLogout: () => i18n.t(localizationConstants.confirmLogoutMsg),
	confirmAutoLicense: () => i18n.t(localizationConstants.confirmAutoLicense),
	confirmChangeLicenseVersion: () => i18n.t(localizationConstants.confirmChangeLicenseVersion),
	confirmChangeLockStatus: () => i18n.t(localizationConstants.confirmChangeLockStatus),
	confirmChangeMachineLockStatus: () => i18n.t(localizationConstants.confirmChangeMachineLockStatus),
	confirmMachineAutoLicense: () => i18n.t(localizationConstants.confirmMachineAutoLicense),
	confirmChangeCompanyLockStatus: () => i18n.t(localizationConstants.confirmChangeCompanyLockStatus),
	confirmCreateDownloadRequest: () => i18n.t(localizationConstants.confirmCreateDownloadRequest)
}

export {
	errorMessages,
	onConfirmMessages
}
