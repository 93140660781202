import CryptoJS from 'crypto-js';
import { LoginResponse, UserProfileResponse } from 'features/login/interface/login.interface';
const KEY: string = 'adsfghjkla2312safaaszAS';

/**
 * function to check if user is logged in or not
 */
const checkLogin = (): boolean => {
	if (localStorage.authData && localStorage.userData) {
		return true;
	} else {
		return false;
	}
};

/**
 * function to get user access token
 */
const getAccessToken = (): boolean | string => {
	try {
		const data = localStorage.authData;
		if (data) {
			const bytes = CryptoJS.AES.decrypt(data.toString(), KEY);
			const decryptedData: LoginResponse = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
			return decryptedData && decryptedData.accessToken ? decryptedData.accessToken : false;
		} else {
			return false;
		}
	} catch (e) {
		return false;
	}
};

/**
 * function to get user data
 */
const getUserData = (): UserProfileResponse => {
	const data = localStorage.userData;
	if (data) {
		const bytes = CryptoJS.AES.decrypt(data.toString(), KEY);
		const decryptedData: UserProfileResponse = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		if (!decryptedData) {
			return {} as UserProfileResponse;
		}
		return decryptedData;
	} else {
		return {} as UserProfileResponse;
	}
};

/**
 * function to get user language
 */
const getUserLanguage = (): string => {
	const data = localStorage.userData;
	// return german as default, if user is not logged in
	if (!data) {
		return 'de';
	}
	const bytes = CryptoJS.AES.decrypt(data.toString(), KEY);
	const decryptedData: UserProfileResponse = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	// return german as default, if user data not found
	if (!decryptedData) {
		return 'de';
	}
	return (decryptedData.user || {}).language || 'de';
};

/**
 * function to get user language
 */
const setUserLanguage = (language: string): void => {
	const data = localStorage.userData;
	if (!data) {
		return;
	}
	const bytes = CryptoJS.AES.decrypt(data.toString(), KEY);
	const decryptedData: UserProfileResponse = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	if (!decryptedData) {
		return;
	}
	decryptedData.user.language = language;
	setUserData(decryptedData);
};

/**
 * function to set user authentication data
 */
const setAuthData = (data: LoginResponse): void => {
	const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), KEY);
	localStorage.setItem('authData', cipherText.toString());
};

/**
 * function to set user authentication data
 */
const setUserData = (data: UserProfileResponse): void => {
	const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), KEY);
	localStorage.setItem('userData', cipherText.toString());
};

/**
 * function to get user authentication data
 */
const getAuthData = (): LoginResponse | {} => {
	const data = localStorage.authData;
	if (data) {
		const bytes = CryptoJS.AES.decrypt(data.toString(), KEY);
		const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		return decryptedData;
	} else {
		return {};
	}
};

/**
 * function to set shop access token data
 */
const setTokenData = (data: any): void => {
	localStorage.setItem('tokenData', JSON.stringify(data));
}

/**
 * function to get shop access token data
 */
const getTokenData = () => {
	let data = {} as any;
	if (localStorage.tokenData) {
		data = JSON.parse(localStorage.tokenData);
	}
	return data;
}

/**
 * function to remove shop access token data
 */
const removeTokenData = (): void => {
	localStorage.removeItem('tokenData');
}

/**
 * function to set register user flag
 */
const setRegisterFlag = (register: any): void => {
	localStorage.setItem('regWithShopify', register);
}

/**
 * function to get register user flag
 */
const getRegisterFlag = () => {
	let flag = 0;
	if (localStorage.regWithShopify === 1 || localStorage.regWithShopify === '1') {
		flag = 1;
	}
	return flag;
}

/**
 * function to remove register user flag
 */
const removeRegisterFlag = (): void => {
	localStorage.removeItem('regWithShopify');
}

/**
 * function to remove user authentication data
 */
const removeAuthData = (): void => {
	localStorage.removeItem('authData');
};

const authService = {
	checkLogin: checkLogin,
	getAccessToken: getAccessToken,
	getUserData: getUserData,
	setAuthData: setAuthData,
	getAuthData: getAuthData,
	removeAuthData: removeAuthData,
	setUserData: setUserData,
	getUserLanguage: getUserLanguage,
	setUserLanguage: setUserLanguage,
	setTokenData: setTokenData,
	getTokenData: getTokenData,
	removeTokenData: removeTokenData,
	setRegisterFlag: setRegisterFlag,
	getRegisterFlag: getRegisterFlag,
	removeRegisterFlag: removeRegisterFlag,
};

export default authService;
