import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { State, Action } from 'shared/interface';
import { createLoadingSelector } from 'shared/util/utility';

import * as RegisterActions from '../store/register.action';
import { MapDispatchProps, Params, MapStateProps } from '../interface/register';
import RegisterForm from '../component/registerForm';

const loadingSelector = createLoadingSelector(['REGISTER_USER']);
const mapStateToProps = (state: State): MapStateProps => {
	return {
		...state.auth,
		loading: loadingSelector(state)
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, Action>): MapDispatchProps => {
	return {
		register: (payload: Params) => dispatch(RegisterActions.registerUser(payload)),
	};
}

export default connect<MapStateProps, MapDispatchProps, {}, State>(mapStateToProps, mapDispatchToProps)(RegisterForm);
