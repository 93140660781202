import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-table/react-table.css';
import 'assets/styles/datatables.min.css'

import './assets/styles/index.scss';
import Layout from 'shared/hoc/layout/component/layout.component'
import AuthService from 'shared/services/auth.service';
import Spinner from 'shared/components/spinner/spinner';
import RouteManager from 'shared/hoc/permission/routeManager';

// import containers, for which lazy loading is not needed, as they will be rendered for all users
import NotFound from 'shared/components/404/404';
import Profile from 'features/profile/container/profile';
import Login from 'features/login/container/login.container';
import RegisterUser from 'features/register/container/register';
import ForgetPassword from 'features/password/container/forgetPassword';
import ResetPassword from 'features/password/container/resetPassword';
import EmailVerification from 'features/emailVerification/component/validate';
import AddShop from 'features/shop/container/shopifyConnect';
import ShopAccessVerification from 'features/shop/container/shopAccessValidate';
import ShopAuthorize from 'features/shop/container/shopAuthorize';
import Permission from 'features/shop/container/permission';

/*
* import async components in order to lazy load the containers, which will be rendered conditionally
* based on the permissions user is having
*/
import * as asyncComponents from 'shared/hoc/asyncComponents';

const App: React.FC = () => {
	const isLoggedIn = AuthService.checkLogin();
	const isShopAccessVerificationPage = window.location.pathname.includes('shop/access');
	const isShopAuthorizePage = window.location.pathname.includes('shop/authorize');

	if (isShopAccessVerificationPage) {
		return (
			<Switch>
				<Route path='/shop/access' component={ShopAccessVerification} />
				<Redirect to='/404' />
			</Switch>
		);
	} else if (isShopAuthorizePage) {
		return (
			<Switch>
				<Route exact path='/shop/authorize' component={Permission} />
				<Route exact path='/shop/authorize/connect' component={ShopAuthorize} />
				<Redirect to='/404' />
			</Switch>
		);
	} else if (isLoggedIn) {
		return (
			<Layout>
				<Suspense fallback={<Spinner />}>
					<Switch>
						<Route
							exact path='/'
							component={asyncComponents.Dashboard} />

						<RouteManager
							permission={['birdhubShopList']}
							exact path='/shop/list' component={asyncComponents.Shop} />
						<RouteManager
							permission={['birdhubShopRead']}
							exact path='/shop/:id' component={asyncComponents.ShopDetail} />
						<RouteManager
							permission={['birdhubShopProductRead']}
							exact path='/shop/:id/item/:itemId' component={asyncComponents.ItemDetail} />

						<Route exact path='/profile' component={Profile} />
						<Route exact path='/404' component={NotFound} />
						<Redirect to='/404' />
					</Switch>
				</Suspense>
			</Layout>
		);
	} else {
		// lazy loading not needed for login, forget password, reset password APIs
		return (
			<Switch>
				<Route path='/login' component={Login} />
				<Route path='/register/user' component={RegisterUser} />
				<Route path='/forget-password' component={ForgetPassword} />
				<Route path='/reset-password/:token' component={ResetPassword} />
				<Route exact path='/email/verification' component={EmailVerification} />
				<Route path='/shopify/shop/add' component={AddShop} />
				<Redirect to='/login' />
			</Switch>
		);
	}
}

export default App;
