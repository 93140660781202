import * as actionTypes from 'store/action-types';
import { ShopState, Action } from 'shared/interface';
import { PER_PAGE } from 'shared/constants/constants';

const initialState: ShopState = {
	shops: [],
	items: [],
	orders: [],
	pagination: {
		current_page: 1,
		from: 1,
		last_page: 1,
		per_page: PER_PAGE,
		to: 1,
		total: 0,
	},
	closeModal: false
}

const reducer = (state: ShopState = initialState, action: Action): ShopState => {
	switch (action.type) {
		case actionTypes.FETCH_SHOPS_INIT:
			return {
				...state,
				pagination: {
					...state.pagination,
					total: 0,
				},
				shops: []
			}
		case actionTypes.FETCH_SHOPS_SUCCESS:
			return {
				...state,
				shops: action.payload.shops,
				pagination: action.payload.pagination,
			};
		case actionTypes.FETCH_SHOP_ITEMS_INIT:
			return {
				...state,
				items: []
			};
		case actionTypes.FETCH_SHOP_ITEMS_SUCCESS:
			return {
				...state,
				items: action.payload.items
			};
		case actionTypes.FETCH_SHOP_ORDERS_INIT:
			return {
				...state,
				orders: []
			};
		case actionTypes.FETCH_SHOP_ORDERS_SUCCESS:
			return {
				...state,
				orders: action.payload.orders
			};
		case 'RESET_MODAL_STATUS':
			return {
				...state,
				closeModal: false
			}
		default:
			return state;
	}
}

export default reducer;
