import React, { Component } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import logo from 'assets/images/logo.png';
import Option from 'shared/components/form/option';
import Button from 'shared/components/form/button';
import { API_CONFIG } from 'shared/constants/constants';
import AuthService from 'shared/services/auth.service';
import HttpService from 'shared/services/http.service';
import localizationConstants from 'shared/util/translation/constants';
import { refreshLanguage } from 'shared/util/localization';
import { Translate } from 'shared/components/translate';
import { checkPermission } from 'shared/util/permission';
import { getSideNavMenu } from 'features/login/store/login.action';
import { UserProfileResponse } from 'features/login/interface/login.interface';

interface OwnProps extends RouteComponentProps { }

/**
 * ShopAuthorize - authorize store from shopify
 */
class ShopAuthorize extends Component<OwnProps> {
	state = {
		isLoading: false,
		isShopRelateSuccess: false,
		shopId: null,
		message: '',
		lang: AuthService.checkLogin() ? AuthService.getUserLanguage() : localStorage.getItem('lang') || 'de',
	};

	render() {
		const isLoggedIn = AuthService.checkLogin();
		const userData = AuthService.getUserData();
		const isAllowed = checkPermission({permission: ['birdhubShopRead']});
		const { isLoading, isShopRelateSuccess, shopId, message } = this.state;
		if (isAllowed && isShopRelateSuccess && shopId) {
			return (<Redirect to={`/shop/${shopId}`} />)
		}
		return (
			<div className='gray-bg login-wrapper validate-card-wrapper'>
				<div className='middle-box loginscreen animated fadeInDown'>
					<div>
						<div className='language-dropdown'>
							<select
								data-testid='language-dropdown'
								value={this.state.lang}
								className='form-control'
								onChange={this.handleLangChange}
							>
								<Option name={localizationConstants.german} value='de' />
								<Option name={localizationConstants.english} value='en' />
							</select>
						</div>
						<div className='text-center' data-testid='logo'>
							<img src={logo} alt='logo' className='logo-name' />
						</div>
						<h1 className='logo-name'>PosBill</h1>
						<div className='card bg-transparent'>
							<span className='text-red'><Translate text={message} /></span>
							<p>
								<Translate text={localizationConstants.authorizeUserInfoText} />
							</p>
							{isLoggedIn &&
								<Button
									className='mt-15 min-width-220px'
									loading={isLoading}
									onClick={this.relateShop}
									type='button'
									btnType='primary'><Translate text={localizationConstants.contineWith} /> {userData.user.name} ?</Button>
							}
							<Button
								className='mt-15 min-width-220px'
								onClick={this.onLogin}
								type='button'
								btnType='default'>{isLoggedIn ? localizationConstants.loginWithDifferentAccount : localizationConstants.login}</Button>
							<Button
								className='mt-15 min-width-220px'
								onClick={this.onSignup}
								type='button'
								btnType='default'>{localizationConstants.signup}</Button>
						</div>
					</div>
				</div>
			</div>
		)
	}

	handleLangChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const language = e.target.value;
		refreshLanguage(language);
		this.setState({ lang: language });
		// refresh to change language
		// window.location.reload();
	}

	/**
	 * removeUserData - remove user data from localstorage
	 */
	removeUserData = () => {
		localStorage.removeItem('authData');
		localStorage.removeItem('userData');
	}

	/**
	 * onLogin - redirect user to login page
	 */
	onLogin = () => {
		this.removeUserData();
		this.props.history.push('/login');
	}

	/**
	 * onSignup - redirect user to register page
	 */
	onSignup = () => {
		this.removeUserData();
		this.props.history.push('/register/user');
	}

	/**
	 * relateShop - relate shop with access token
	 */
	relateShop = () => {
		const data = AuthService.getTokenData();
		const isTokenDataAvailable = data.token && data.permission === 1 && data.tokenType === 'authorize';
		if (isTokenDataAvailable) {
			const params = {
				token: data.token,
			};
			this.setState({ isLoading: true });
			HttpService.post(API_CONFIG.path.relateShop, params).then((response) => {
				HttpService.get(API_CONFIG.path.profile).then((profileResponse: UserProfileResponse) => {
					profileResponse.sidebarMenu = getSideNavMenu(profileResponse);
					AuthService.setUserData(profileResponse);
					refreshLanguage(profileResponse.user.language || 'de');
					AuthService.removeTokenData();
					this.setState({ isLoading: false, isShopRelateSuccess: true, shopId: response.shop.id });
				}).catch(() => {
					this.setState({ isLoading: false, isShopRelateSuccess: true, shopId: response.shop.id });
				});
			}).catch(() => {
				this.setState({ isLoading: false, isShopRelateSuccess: false, message: localizationConstants.invalidToken });
			});
		}
	}
}

export default ShopAuthorize;
