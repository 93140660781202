import React, { Component } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import queryString from 'query-string';

import Option from 'shared/components/form/option';
import Button from 'shared/components/form/button';
import { API_CONFIG } from 'shared/constants/constants';
import AuthService from 'shared/services/auth.service';
import HttpService from 'shared/services/http.service';
import localizationConstants from 'shared/util/translation/constants';
import { refreshLanguage } from 'shared/util/localization';
import { Translate } from 'shared/components/translate';

interface OwnProps extends RouteComponentProps<{ token: string }> { }

interface UIState {
	isLoading: boolean;
	isShopRelateSuccess: boolean;
	shopId: number | null;
	message: string;
	lang: string;
}

/**
 * Permission - Show permission list info to ask user for
 */
class Permission extends Component<OwnProps> {
	state: UIState = {
		isLoading: false,
		isShopRelateSuccess: false,
		shopId: null,
		message: '',
		lang: AuthService.checkLogin() ? AuthService.getUserLanguage() : localStorage.getItem('lang') || 'de',
	};

	componentDidMount = () => {
		// this.verifyToken();
	}

	render() {
		const { isLoading, isShopRelateSuccess, shopId, message } = this.state;
		if (isShopRelateSuccess) {
			return (<Redirect to={`/shop/${shopId}`} />);
		}
		return (
			<div className='gray-bg full-height'>
				<div className='permission-box-wrapper'>
					<div className='language-dropdown'>
						<select
							data-testid='language-dropdown'
							value={this.state.lang}
							className='form-control'
							onChange={this.handleLangChange}
						>
							<Option name={localizationConstants.german} value='de' />
							<Option name={localizationConstants.english} value='en' />
						</select>
					</div>
					<div className='ibox float-e-margins'>
						<div className='ibox-title text-capitalize d-flex justify-content-center'>
							<Translate text={localizationConstants.permissions} />
						</div>
						<div className={`ibox-content ${isLoading ? 'form-overlay' : ''}`}>
							{!isLoading && message &&
								<div className='content'>
									<div className='msg-wrapper'>
										<Translate text={message} />
										<a className='btn btn-primary mt-15' href='/'>
											<Translate text={localizationConstants.backToHome} />
										</a>
									</div>
								</div>
							}
							{!message &&
								<>
									<div className='content'>
										<div className='content-header-text'>
											<span className='font-bold'><Translate text={localizationConstants.posbillConnect} /> </span>
											<Translate text={localizationConstants.isRequestingPermission} />
										</div>
										<div className='info-list'>
											<div className='info'>
												<p className='info-label-text'><Translate text={localizationConstants.readMyProductInformation} /></p>
												<p className='info-short-desc'>
												<Translate text={localizationConstants.accesssToProductProductVariant} />
												</p>
											</div>
											{/* <hr/>
											<div className='info'>
												<p className='info-label-text'><Translate text={localizationConstants.readMyOrderInfo} /></p>
												<p className='info-short-desc'>
												<Translate text={localizationConstants.accesssToAbandonedCheckouts} />
												</p>
											</div> */}
											<hr/>
											<div className='info'>
												<p className='info-label-text'><Translate text={localizationConstants.readMyInventoryInfo} /></p>
												<p className='info-short-desc'>
												<Translate text={localizationConstants.accessToInventoryLevel} />
												</p>
											</div>
											<hr/>
											<div className='info'>
												<p className='info-label-text'><Translate text={localizationConstants.readMyLocationInfo} /></p>
												<p className='info-short-desc'>
												<Translate text={localizationConstants.accessToLocation} />
												</p>
											</div>
											{/* <hr/>
											<div className='info'>
												<p className='info-label-text'><Translate text={localizationConstants.readMyCustomerInfo} /></p>
												<p className='info-short-desc'>
												<Translate text={localizationConstants.accessToCustomerAndSavedSearch} />
												</p>
											</div> */}
										</div>
									</div>
									<hr />
									<div className='ibox-action'>
										<div className='action-btn-wrapper'>
											<div className='action-btns permission-btns'>
												<Button
													onClick={this.deniedPermissionAccess}
													type='button'
													className='mr-2 min-width-80px'>{localizationConstants.deny}</Button>
												<Button
													className='min-width-80px'
													onClick={this.allowPermission}
													type='button'
													btnType='primary'>{localizationConstants.allow}</Button>
											</div>
										</div>
									</div>
								</>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
	handleLangChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const language = e.target.value;
		refreshLanguage(language);
		this.setState({ lang: language });
		// refresh to change language
		// window.location.reload();
	}

	/**
	 * allowPermission - set token related data
	 */
	allowPermission = () => {
		const parsed = queryString.parse(this.props.location.search);
		const data = {
			token: parsed.token,
			tokenType: 'authorize',
			permission: 1
		};
		AuthService.setTokenData(data);
		this.props.history.push('/shop/authorize/connect');
	}

	/**
	 * deniedPermissionAccess - remove permission related data
	 */
	deniedPermissionAccess = () => {
		const parsed = queryString.parse(this.props.location.search);
		const params = {
			token: parsed.token,
		};
		HttpService.post(API_CONFIG.path.deniedShopPermission, params);
		this.setState({ message: localizationConstants.shopifyInstallationCalcelled });
	}

	/**
	 * verifyToken - verify if token is valid or not
	 */
	verifyToken = () => {
		const parsed = queryString.parse(this.props.location.search);
		const params = {
			token: parsed.token,
		};
		this.setState({ isLoading: true });
		HttpService.post(API_CONFIG.path.login, params).then((response: any) => {
			this.setState({ isLoading: false });
		}).catch(() => {
			this.setState({ isLoading: false, message: localizationConstants.invalidToken });
		});
	}
}

export default Permission;
