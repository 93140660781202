import React from 'react';
import CustomModal from 'shared/components/modal/modal';
import { FieldConfig } from 'shared/components/form/inputTypes';
import { FormikValues } from 'formik';
import { CustomForm } from 'shared/components/form/form';
import localizationConstants from 'shared/util/translation/constants';
import * as schema from 'shared/constants/validation-schema';
import { checkPermission } from 'shared/util/permission';

interface Props {
	show: boolean;
	loading: boolean;
	handleClose: React.ReactEventHandler<{}>;
	handleConfirm: (values: FormikValues) => void;
	initialValues: {
		name: string;
		email: string;
		language: string;
		deb_auto_id?: string;
	};
}

const UpdateProfile: React.FC<Props> = (props) => {
	const isDeniedToChangeDebAuto = checkPermission({ permission: ['birdhubShopList'] });
	if(isDeniedToChangeDebAuto) {
		delete props.initialValues.deb_auto_id;
	}
	return (
		<CustomModal
			className='non-panel-form'
			show={props.show}
			handleClose={props.handleClose}>
			<CustomForm
				showTitle
				showLabels
				title={localizationConstants.updateProfileTitle}
				initialValues={props.initialValues}
				schema={schema.updateProfileValidationSchema}
				onSubmit={props.handleConfirm}
				cancelSubmit={props.handleClose}
				loading={props.loading}
				fieldConfig={isDeniedToChangeDebAuto ? fieldConfig2 : fieldConfig}
			/>
		</CustomModal>
	)
}

// field config of update profile form
const fieldConfig: FieldConfig[] = [
	{ type: 'text', label: localizationConstants.name, name: 'name' },
	// { type: 'text', label: localizationConstants.email, name: 'email' },
	{ type: 'number', label: localizationConstants.debAuto, name: 'deb_auto_id' },
	{
		type: 'dropdown',
		label: localizationConstants.language,
		name: 'language',
		otherOptions: {
			dropDownOptions: [
				{ name: localizationConstants.german, value: 'de' },
				{ name: localizationConstants.english, value: 'en' },
			]
		}
	},
];

// field config without debAuto to update profile form
const fieldConfig2: FieldConfig[] = [
	{ type: 'text', label: localizationConstants.name, name: 'name' },
	{
		type: 'dropdown',
		label: localizationConstants.language,
		name: 'language',
		otherOptions: {
			dropDownOptions: [
				{ name: localizationConstants.german, value: 'de' },
				{ name: localizationConstants.english, value: 'en' },
			]
		}
	},
];

export default UpdateProfile;
