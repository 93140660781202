const en = {
	"dashboardTitle": "dashboard",
	"autoGeneratedLicenseGraphTitle": "Auto Generated License",
	"upcomingCreatedLicenseGraphTitle": "Total Created License",
	"daily": "daily",
	"weekly": "weekly",
	"monthly": "monthly",
	"today": "Today",
	"yesterday": "Yesterday",
	"thisWeek": "This Week",
	"lastWeek": "Last Week",
	"thisMonth": "This Month",
	"lastMonth": "Last Month",
	"custom": "Custom",
	"backBtnText": "back",
	"organizationsTitle": "organizations",
	"superAdmin": "super admin",
	"license": "license",
	"support": "support",
	"licenses": "licenses",
	"incomingLicensesTitle": "incoming licenses",
	"machineListTitle": "My Machines",
	"licenseNumberView": "license number view",
	"users": "users",
	"user": "User",
	"list": "list",
	"create": "create",
	"rolesAndPermissions": "roles & permissions",
	"roles": "roles",
	"role": "Role",
	"status": "Status",
	"permissions": "permissions",
	"addUser": "add user",
	"supportList": "support list",
	"licenseList": "license list",
	"superAdminList": "super admin list",
	"logout": "Log out",
	"home": "Home",
	"view": "view",
	"createLicense": "create license",
	"delete": "Delete",
	"restore": "Restore",
	"edit": "Edit",
	"all": "All",
	"customer": "Customer",
	"Customer": "Customer",
	"customers": "Customers",
	"leads": "Leads",
	"search": "search",
	"licenseNumbers": "license numbers",
	"licenseInformation": "license information",
	"organizationDetails": "organization details",
	"details": "Details",
	"profile": "profile",
	"editUser": "edit user",
	"notFoundMsg": "Sorry, but the page you are looking for has note been found. Try checking the URL for error, then hit the refresh button on your browser or try found something else in our app.",
	"pageNotFound": "Page Not Found",
	"actions": "Actions",
	"orgTimeline": "Organization Timeline",
	"noDataFoundText": "No data found",
	"serialNumber": "No.",
	"name": "Name",
	"email": "Email",
	"recipientEmail": "recipient email",
	"senderEmail": "sender email",
	"password": "Password",
	"oldPassword": "Old Password",
	"newPassword": "New Password",
	"confirmPassword": "Confirm Password",
	"passwordConfirmation": "Password Confirmation",
	"debAuto": "DEB-Auto",
	"createdAt": "Created At",
	"updatedAt": "Updated At",
	"functions": "Functions",
	"hardwareKey": "Hardware Key",
	"hardwareId": "Hardware ID",
	"timestampInsert": "Inserted At",
	"timestampUpdate": "Updated At",
	"activeLicense": "Active License",
	"nextLicense": "Next License",
	"posbillVersion": "PosBill Version",
	"partnerId": "Partner Id",
	"branchId": "Branch Id",
	"isLocked": "Is Locked",
	"emailVerifiedAt": "Email Verified At",
	"zip": "Zip",
	"telefax": "Telefax",
	"mobile": "Mobile",
	"website": "Website",
	"billingPeriod": "Billing Period",
	"expiringDate": "Expiring Date",
	"licenseNumber": "License Number",
	"ustId": "Ust Id",
	"iban": "Iban",
	"bic": "Bic",
	"bank": "Bank",
	"saleTaxId": "Sale Tax Id",
	"vatNumber": "Vat Number",
	"salesForceId": "Sales Force Id",
	"acceptAgbDate": "Accept Agb Date",
	"acceptSwnDate": "Accept Swn Date",
	"expiryDateType": "Expiry Date Type",
	"year": "Year",
	"month": "Month",
	"typeValue": "Type Value",
	"value": "Value",
	"expiryDate": "Expiry Date",
	"autoLicense": "Auto License",
	"version": "Version",
	"autoUpdate": "Auto Update",
	"validFrom": "Valid From",
	"validTo": "Valid To",
	"newLicense": "New License",
	"manageModules": "Manage Modules",
	"lock": "Lock",
	"locked": "Locked",
	"unlock": "Unlock",
	"licenseLock": "License lock",
	"licenseUnlock": "License unlock",
	"machineLock": "Machine lock",
	"machineUnlock": "Machine unlock",
	"licenseLocked": "License number locked",
	"licenseUnlocked": "License number unlocked",
	"machineLocked": "Machine locked",
	"machineUnlocked": "Machine unlocked",
	"machineAutoLicenseEnabled": "Machine Auto License Enabled",
	"machineAutoLicenseDisabled": "Machine Auto License Disabled",
	"sendLicenseEmail": "Send license email",
	"generate": "generate",
	"copyEmailAndPasswordToClipboard": "copy email and password to clipboard",
	"submit": "submit",
	"submitWithEmail": "submit with email",
	"cancel": "cancel",
	"yes": "YES",
	"no": "NO",
	"archived": "archived",
	"active": "active",
	"Active": "Active",
	"addNewUser": "Add New User",
	"id": "Id",
	"module": "Module",
	"close": "Close",
	"noUsersAvailable": "no users available",
	"slug": "Slug",
	"language": "Language",
	"german": "German",
	"english": "English",
	"noPermissionsAvailable": "no permissions available",
	"changePassword": "Change Password",
	"updateProfileTitle": "Update Your Profile",
	"changePasswordTitle": "Change Your Password",
	"editUserDetails": "Edit User Details",
	"newRegisteredUser": "New Register User",
	"inActive": "InActive",
	"blocked": "Blocked",
	"incomingLicenseList": "Incoming License List",
	"licenseNumbersList": "License Numbers List",
	"forgetPassword": "Forget Password",
	"getPasswordResetCode": "Get Password Reset Code",
	"backToLogin": "Back to Login",
	"noMachinesFound": "no machines found",
	"resetYourPassword": "Reset Your Password",
	"resetPassword": "Reset Password",
	"login": "Login",
	"register": "Register",
	"privacyPolicy": "Privacy Policy",
	"hello": "Hello",
	"licenseDetailsAreHere": "Your License details for PosBill are here",
	"thankYou": "Thank You",
	"yourPosbillTeam": "Your PosBill Team.",
	"licenseReport": "License Report",
	"standard": "Standard",
	"premium": "Premium",
	"demo": "Demo",
	"fun": "Fun",
	"default": "Default",
	"sql": "SQL",
	"addItem": "Add Item",
	"OK": "OK",
	"addLicense": "Add License",
	"addHardware": "Add Hardware",
	"add": "Add",
	"addWithEmail": "add with email",
	"send": "send",
	"openEmail": "open email",
	"addNewRole": "Add New Role",
	"editRole": "Edit Role",
	"customerData": "Customer Data",
	"name1": "Name 1",
	"name2": "Name 2",
	"street": "Street",
	"zipCode": "Zip Code",
	"city": "City",
	"nation": "Nation",
	"phone": "Phone",
	"fax": "Fax",
	"ust": "Ust",
	"expiryDateValidate": "Please select expiry date",
	"activateAutoLicense": "Activate Auto License",
	"to": "To",
	"cc": "CC",
	"subject": "Subject",
	"content": "Content",
	"requiredFieldValidation": "{{field}} is required!",
	"fieldSelectionValidation": "please select {{field}}",
	"exactLengthValidation": "{{field}} must be having exact {{length}} characters!",
	"minLengthStrValidation": "{{field}} must be longer than {{length}} characters!",
	"minLengthArrValidation": "{{field}} must contain minimum {{length}} items!",
	"maxLengthStrValidation": "{{field}} must be shorter than {{length}} characters!",
	"maxLengthArrValidation": "{{field}} must contain maximum {{length}} items!",
	"minValueValidation": "{{field}} must be greater than or equal to {{value}}!",
	"maxValueValidation": "{{field}} must be less than or equal to {{value}}!",
	"typeValidation": "{{field}} must be a valid {{type}}!",
	"hardwareKeyValidation": "hardware key must contain digits only (must not start with 0)",
	"passwordMatchValidation": "Passwords do not match",
	"domainMatchValidation": "Please enter valid domain name!",
	"confirmRestoreMsg": "Are you sure you want to restore {{field}}?",
	"confirmDeleteMsg": "Are you sure you want to remove {{field}}?",
	"confirmLogoutMsg": "Are you sure you want to logout?",
	"confirmAutoLicense": "Are you sure you want to update auto-license state of given license?",
	"confirmChangeLicenseVersion": "Are you sure you want to change license version?",
	"confirmChangeLockStatus": "Are you sure you want to change license lock status?",
	"confirmChangeMachineLockStatus": "Are you sure you want to change machine lock status?",
	"confirmMachineAutoLicense": "Are you sure you want to change machine auto-license status?",
	"noLicensesFound": "no licenses found",
	"noExpiredLicensesFound": "no expired licenses found",
	"addHardwareNotAllowed": "You can not add hardware for this organization, as the customer ID is not valid",
	"customerIdRequirements": "(Customer ID field must be a number, and must be between 1 and 99999)",
	"givenCustomerIdIsNotValid": "Customer ID - {{customerId}} is not valid",
	"relateMachineToCustomer": "Relate Machine To Customer",
	"newCustomer": "New Customer",
	"customerId": "Customer Id",
	"copied": "Copied",
	"copyText": "Copy Text",
	"difference": "Difference",
	"relate": "Relate",
	"totalRecords": "Total Records",

	"birdhub": "Birdhub",
	"birdhubTitle": "Birdhub",
	"stores": "Stores",
	"storeList": "Store List",
	"inventory": "Inventory",
	"shopDetail": "Shop Detail",
	"backToShopify": "Back to Shopify",
	"connectWithShopify": "Connect With Shopify",
	"connectToShopify": "Connect To Shopify",
	"addShop": "Add Shop",
	"addNewShop": "Add New Shop",
	"shopName": "Shop Name",
	"domain": "Domain",
	"currency": "Currency",
	"plan": "Plan",
	"info": "Info",
	"marketplace": "Marketplace",
	"itemId": "Item Id",
	"itemName": "Item Name",
	"customerName": "Customer Name",
	"customerEmail": "Customer Email",
	"orderName": "Order Name",
	"orderDate": "Order Date",
	"totalPrice": "Total Price",
	"quantity": "Quantity",
	"itemPrice": "Item Price",
	"purchasePrice": "Purchase Price",
	"itemDetail": "Item Detail",
	"stockDetail": "Stock Detail",
	"variantId": "Variant Id",
	"locationId": "Location Id",
	"stock": "Stock",
	"reserveStock": "Reserve Stock",
	"reorderStock": "Reorder Stock",
	"syncOrders": "Sync Orders",
	"shopUninstalledText": "Your store was uninstalled from shopify",
	"initialSetup": "Initial Setup",
	"birdhubSetup": "Birdhub Setup",
	"registerWebhooks": "Register Webhooks",
	"webhookInstall": "Webhook install",
	"initialSync": "Initial Sync",
	"locations": "Locations",
	"lroduct": "Product",
	"orders": "Orders",
	"syncLocation": "Sync Location",
	"syncProduct": "Sync Product",
	"initialSetupInfoText": "You need to Setup the Store first by clicking on Setup. This will configurate the app on your Store.",
	"registerWebhookInfoText": "If you want the products up to date than register the Webhooks for liveupdate.",
	"initialSyncInfoText": "Here you can sync your items manually. This is useful if you install the app for the first time.",
	"note": "Note",
	"uninstall": "Uninstall",
	"registerWebhookText": "Are you sure, you want to register webhook?",
	"uninstallWebhookText": "Are you sure, you want to uninstall webhook?",
	"registerWebhookNote": "Your auto sync from shoipify will be enable when you register the webhook.",
	"uninstallWebhookNote": "When you uninstall the webhook, you no longer get real updates from Shopify.",

	"internal": "internal",
	"global": "global",
	"local": "local",
	"birdhubAdmin": "birdhubAdmin",
	"birdhubAdminTitle": "Birdhub Admin",
	"credentials": "Credentials",
	"credentialList": "Credential List",
	"addNewCredential": "Add New Credential",
	"addCredential": "Add Credential",
	"updateCredential": "Update Credential",
	"credentialInfo": "Credential Info",
	"apiId": "Api Id",
	"apiSecretKey": "Api Secret Key",
	"type": "Type",
	"notes": "Notes",

	"phoenixAdmin": "phoenixAdmin",
	"phoenixAdminTitle": "Phoenix Admin",
	"companyList": "Company List",
	"companyDetail": "Company Detail",
	"companyId": "Company Id",
	"phoenixIncomingLicenseList": "Phoenix Incoming License List",
	"phoenixIncomingLicenseTitle": "Phoenix Incoming Licenses",
	"isEmailVerified": "Is Email Verified",
	"verified": "verified",
	"pending": "pending",
	"subscriptions": "Subscriptions",
	"subscription": "Subscription",
	"subscriptionId": "Subscription Id",
	"additionalUsers": "Additional Users",
	"additionalFeatures": "Additional Features",
	"bankInfo": "Bank Info",
	"universeId": "Universe Id",
	"companyName": "Company Name",
	"isVerified": "isVerified",
	"manageSubscription": "Manage Subscription",
	"resetMasterPassword": "Reset Master Password",
	"companyLocked": "Company Locked",
	"companyUnlocked": "Company Unlocked",
	"confirmChangeCompanyLockStatus": "Are you sure you want to change company lock status?",

	"modules": "Modules",
	"moduleList": "Module List",
	"addNewModule": "Add New Module",
	"addModule": "Add Module",
	"updateModule": "Update Module",
	"price": "Price",

	"package": "Package",
	"packages": "Packages",
	"packageList": "Package List",
	"updatePackage": "Update Package",

	"startPhoenixNow": "Start Phoenix now",
	"companyAlreadyValidatedMessage": "Company already verified!",
	"companyVerificationSuccessMessage": "Your registration was successfull!",
	"companyVerificationFailMessage": "Sorry. Your company could not be verified!",

	"emailAlreadyValidatedMessage": "Email already verified!",
	"emailVerificationSuccessMessage": "Email verified successfully!",
	"emailVerificationFailMessage": "Sorry. Your email could not be verified!",

	"companyListTitle": "My Companies",
	"hospitality": "Hospitality",
	"retail": "Retail",
	"shopListTitle": "My Shops",
	"backToHome": "Back to Home",
	"posbillConnect": "PosBill Connect",
	"isRequestingPermission": "is requesting permission to do following:",
	"readMyProductInformation": "Read my products information",
	"accesssToProductProductVariant": "Access to Product, Product Variant, Product Image, Collect, Custom Collection and Smart Collection",
	"readMyOrderInfo": "Read my orders information",
	"accesssToAbandonedCheckouts": "Access to Abandoned checkouts, Fulfillment, Order, and Transaction",
	"readMyInventoryInfo": "Read my inventory information",
	"accessToInventoryLevel": "Access to Inventory Level and Inventory Item",
	"readMyLocationInfo": "Read my locations information",
	"accessToLocation": "Access to Location",
	"readMyCustomerInfo": "Read my customers information",
	"accessToCustomerAndSavedSearch": "Access to Customer and Saved Search",
	"deny": "Deny",
	"allow": "Allow",
	"invalidToken": "Invalid Token!",
	"shopifyInstallationCalcelled": "shopify installed process cancelled",
	"contineWith": "Continue with",
	"loginWithDifferentAccount": "Login with Different Account",
	"signup": "Signup",
	"authorizeUserInfoText": "Continue with your existing PosBill account or create one if you don't have one yet, so you can link your shop to the PosBill Universe",
	"additionalInformation": "Additional Information",
	"shopNotFound": "Shop not found",

	"terracloud": "TSE Backup",
	"terracloudBackupList": "TSE Backup List",
	"download": "Download",
	"directoryName": "Directory Name",
	"lastModifiedDate": "Last Modified Date",
	"fileName": "File Name",
	"fileSize": "File Size",
	"serial_number": "Serial Number",
	"checksum": "Checksum",
	"workingDate": "Working Date",
	"progressing": "Progressing",
	"inProgress": "In Progress",
	"uploaded": "Uploaded",
	"downloaded": "Downloaded",
	"createDownloadRequest": "Create Download Request",
	"activeBackupData": "Active Backup Data",
	"backupInProgressText": "Your backup is in progress ...",
	"backupReadyText": "Your backup is ready",
	"backupFiles": "Backup files",
	"generateDownloadLink": "Generate Download Link",
	"generatedLinkText": "Backup link is generated click on download button",
	"downloadBackup": "Download Backup",
	"confirmCreateDownloadRequest": "If you request for new backup, then old downloaded backup will be removed. Are you sure want to proceed?",

	"permission-module-Organizations": "Organizations",
	"permission-module-Widgets": "Widgets",
	"permission-module-Roles": "Roles",
	"permission-module-Users": "Users",
	"permission-module-EmailTemplates": "Email Templates",
	"permission-module-EmailTemplateVariables": "Email Template Variables",
	"permission-module-Permissions": "Permissions",
	"permission-module-Licenses": "Licenses",
	"permission-module-CustomerLicense": "Customer License",
	"permission-module-Graphs": "Graphs",
	"permission-module-BirdHubCredentials": "Birdhub Credentials",
	"permission-module-PhoenixModules": "Phoenix Modules",
	"permission-module-PhoenixCompanies": "Phoenix Companies",
	"permission-module-CustomerPhoenixLicense": "Phoenix Customer Licenses",
	"permission-module-PhoenixPackages": "Phoenix Packages",
	"organizations.superAdminIndex": "Super Admin List",
	"organizations.licenseIndex": "License List",
	"organizations.supportIndex": "Support List",
	"organizations.show": "View Organization",
	"organizations.timeline": "View Organization's Timeline",
	"organizations.superAdminShow": "Super Admin Detail View",
	"organizations.licenseShow": "License Detail View",
	"organizations.supportShow": "Support Detail View",
	"incomingLicense.incomingRequestList": "Incoming License Requests",
	"incomingLicense.incomingAssignCustomer": "Assign Existing Customer to Machine",
	"incomingLicense.createNewCustomer": "Create and Assign new Customer to Machine",
	"widget.userStatistics": "Statistics Widgets",
	"roles.index": "View All Roles",
	"roles.store": "Create Role",
	"roles.show": "View Role",
	"roles.update": "Update Role",
	"roles.destroy": "Delete Role",
	"users.index": "View All Users",
	"users.store": "Create User",
	"users.archive": "View Archive Users List",
	"users.show": "View User Details",
	"users.update": "Update User",
	"users.destroy": "Delete User",
	"users.restore": "Restore User",
	"emailTemplate.index": "View All Email Templates",
	"emailTemplate.store": "Create Email Template",
	"emailTemplate.show": "View Email Template",
	"emailTemplate.update": "Update Email Template",
	"emailTemplate.destroy": "Delete Email Template",
	"emailTemplateVariable.store": "Create Email Template Variable",
	"emailTemplateVariable.destroy": "Delete Email Template Variable",
	"permissions.index": "View All Permissions",
	"licenses.incoming": "Incoming Licenses",
	"licenses.machine_list": "Machine List",
	"licenses.license_numbers": "License Numbers",
	"customerLicense.machineIndex": "Customer Machines",
	"customerLicense.machineStore": "Create Customer Machine",
	"customerLicense.machineShow": "View Customer Machine",
	"customerLicense.machineUpdate": "Update Customer Machine",
	"customerLicense.machineLicenses": "Machine Licenses",
	"customerLicense.licenseStore": "Create Customer license",
	"customerLicense.sendEmail": "Send License Email",
	"customerLicense.moduleList": "Manage Modules",
	"customerLicense.moduleStore": "Module Save",
	"customerLicense.moduleUpdate": "Module Update",
	"customerLicense.licenseUpdate": "Customer License Update",

	"graphs.autoGeneratedLicenseGraph": "Show Auto Generated License Graph",
	"graphs.upcomingCreatedLicenses": "Show Upcoming Created Licenses Graph",

	"bird_hub.credentials.index": "View All Birdhub Credentials",
	"bird_hub.credentials.show": "View Birdhub Credential",
	"bird_hub.credentials.store": "Create Birdhub Credential",
	"bird_hub.credentials.update": "Update Birdhub Credential",

	"phoenix.incomingLicenseRequestList": "Phoenix Incoming License Requests",
	"phoenix.incomingAssignCustomer": "Phoenix Assign Existing Customer to Machine",
	"phoenix.createNewCustomer": "Phoenix Create and Assign new Customer to Machine",
	"phoenix.modules.index": "View All Phoenix Modules",
	"phoenix.modules.store": "Create Phoenix Module",
	"phoenix.modules.update": "Update Phoenix Module",
	"phoenix.modules.destroy": "Delete Phoenix Module",
	"phoenix.companies.index": "View All Phoenix Companies",
	"phoenix.companies.show": "View Phoenix Company",
	"phoenix.subscriptions.index": "View All Phoenix Company Subscriptions",
	"phoenix.subscriptions.show": "View Phoenix Company Subscription",

	"phoenix.packages.index": "View All Phoenix Packages",
	"phoenix.packages.update": "Update Phoenix Packages",

	"customerPhoenixLicense.changeMasterPassword": "Change Master Password",
	"customerPhoenixLicense.companyIndex": "View All Company List",
	"customerPhoenixLicense.companyUpdate": "Update Company Status",
	"customerPhoenixLicense.modulesIndex": "View All Company Module List",
	"customerPhoenixLicense.modulesUpdate": "Update Company Module",
	"customerPhoenixLicense.subscriptionsIndex": "View All Company Subscription",
	"customerPhoenixLicense.subscriptionsShow": "View Company Subscription",
	"customerPhoenixLicense.subscriptionsStore": "Create Company Subscription",

	"tse.history": "View All TSE Backup History List",
	"tse.download.request_create": "Create TSE Backup Download Request",
	"tse.download.request_get": "Get Active Backup Data",
	"tse.download": "Download TSE Backup",
}

export default en;
