import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { FormikValues } from 'formik';

import AuthService from 'shared/services/auth.service';
import { CustomForm } from 'shared/components/form/form';
import { FieldConfig } from 'shared/components/form/inputTypes';

import { MapDispatchProps, Params, MapStateProps } from '../interface/register';
import LoginWrapper from 'shared/hoc/auth/loginWrapper';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/components/translate';
import * as schema from 'shared/constants/validation-schema';
import { PRIVACY_POLICY_URL } from 'shared/constants/constants';

interface OwnProps extends MapDispatchProps, MapStateProps { }

const RegisterForm: React.FC<OwnProps> = (props) => {
	const isLoggedIn = AuthService.checkLogin();
	if (isLoggedIn || props.closeModal) {
		return (<Redirect to='/login' />)
	}

	const onRegister = (values: FormikValues) => {
		const registerParams: Params = {
			name: values.name,
			email: values.email,
			password: values.password,
			deb_auto_id: values.debAuto
		};
		props.register(registerParams);
	};

	return (
		<LoginWrapper>
			<CustomForm
				title={localizationConstants.register}
				showLabels={true}
				formClassName='m-t'
				schema={schema.registerUserValidationSchema}
				onSubmit={onRegister}
				loading={props.loading}
				fieldConfig={fieldConfig}
				submitBtnText={localizationConstants.register}
				submitBtnClassName='block full-width m-b'
			/>
			<div className='d-flex justify-content-space-around'>
				<Link to='/login'>
					<small><Translate text={localizationConstants.login} /></small>
				</Link>
				<a target='_blank' rel="noopener noreferrer" href={PRIVACY_POLICY_URL}>
					<small><Translate text={localizationConstants.privacyPolicy} /></small>
				</a>
			</div>
		</LoginWrapper>
	)
}

const fieldConfig: FieldConfig[] = [
	{ type: 'text', label: localizationConstants.name, name: 'name' },
	{ type: 'email', label: localizationConstants.email, name: 'email' },
	{ type: 'password', label: localizationConstants.password, name: 'password' },
	{ type: 'password', label: localizationConstants.confirmPassword, name: 'confirmPassword' },
	{ type: 'number', label: localizationConstants.debAuto, name: 'debAuto' }
];

export default RegisterForm;
